import React, { ChangeEventHandler, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/reducers/store'
import * as actions from '../../store/actions/translations'
import { ErrorHandling } from '../general/ErrorHandling'
import { LineTranslationType } from '../../types/reducers'
import { BackToPageDetailsButton } from '../documents/PageNavigation'
import { HelpButton } from '../general/HelpButton'

const organizeText = (inputText: string) => {
    let combinedText = inputText.replaceAll('\n|# ', ' ')

    // Step 2: Remove dashes and combine the words around the dash
    combinedText = combinedText.replace(/ *[‐-] */g, '')

    // Step 3: Separate the lines using dots
    const lines = combinedText.split('.')
    // Step 4: combined any line that are less than 10 characters with previous one
    for (let i = 1; i < lines.length; i++) {
        let previousLine = lines[i - 1]
        if (lines[i].length < 10) {
            previousLine += lines[i]
            lines[i - 1] = previousLine
            lines.splice(i, 1)
            i--
        }
    }
    return (
        lines
            .map((line) => line.trim())
            .join('.\n|> \n|# ')
            .trim() + '.\n|> \n|+ '
    )
}

const validateText = (text: string): string => {
    // Valid text should have equal numbers of lines that start with > and lines that don't after ignoring lines
    // starting with +
    const linesLength = Array.from(text.matchAll(/^\|#.*$/gm)).length
    const linesWithTranslationLength = Array.from(text.matchAll(/^\|>.*$/gm)).length
    if (linesWithTranslationLength === 0) {
        return ''
    }

    if (linesLength > linesWithTranslationLength) {
        return `Some lines are missing translations (${linesLength} > ${linesWithTranslationLength})`
    }
    if (linesLength < linesWithTranslationLength) {
        return `Too many translations (${linesWithTranslationLength} > ${linesLength}  )`
    }
    return ''
}

const lines2text = (lines: LineTranslationType[]) =>
    // Given list of translations coming from Django, convert it into editable text to present to user
    lines
        .map((line) => {
            let text = '|# ' + line.text
            if (line.translation !== null) {
                text += '\n|> ' + line.translation
            }
            if (line.comment !== null) {
                text += '\n|+ ' + line.comment
            }
            return text
        })
        .join('\n')

export const TranslationComponent = () => {
    const params = useParams()
    const pageID: string = params.page!
    const translation = useSelector((state: RootState) => state.translations[pageID] || {})
    const translationText: string = lines2text(translation.lines || [])
    const [text, setText] = useState('')
    const dispatch = useDispatch()

    useEffect(() => {
        setText(translationText)
    }, [translationText])

    if (!params.page) {
        return <div>Incorrect URL</div>
    }
    const onTextChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
        setText(e.target.value)
    }

    const onSubmitTranslation = () => {
        const translations: LineTranslationType[] = []
        let translation: LineTranslationType = { text: '', translation: '', comment: '' }
        text.split('|').forEach((line) => {
            line = line.trim()
            if (line.startsWith('#')) {
                if (translation.text) {
                    // add previous translation to the list
                    translations.push(translation)
                }
                translation = { text: line.substring(1), translation: '', comment: '' }
            } else if (line.startsWith('>')) {
                translation.translation = line.substring(1)
            } else if (line.startsWith('+')) {
                translation.comment = line.substring(1)
            }
        })
        if (translation?.text) {
            // add the last translation to the list
            translations.push(translation)
        }
        dispatch(actions.submitTranslations({ pageId: pageID, lines: translations }))
    }

    const onTextBlur = () => {
        dispatch(actions.saveTranslation(pageID, text))
    }

    if (translation.translationText === undefined) {
        return <ErrorHandling error="" loading={true} />
    }

    const textError = validateText(text)

    function onOrganizeClick() {
        setText(organizeText(text))
    }

    function onResetClick() {
        setText(translationText || '')
    }

    return (
        <div className="container">
            <BackToPageDetailsButton />
            <HelpButton>
                <h4>Formatting Guide:</h4>
                <p>
                    Lines starting with <b>|#</b> are the original text. Lines starting with <b>|&gt;</b> are the
                    translations. Lines starting with <b>|+</b> are comments.
                </p>
                <p>
                    <b>Example:</b>
                    <br />
                    <code>
                        |# Original text
                        <br />
                        |&gt; Translated text
                        <br />
                        |+ Multiline
                        <br />
                        comment
                        <br />
                    </code>
                </p>
                <h4>Organize button:</h4>
                <p>
                    The organize button tries to put each complete sentence in a single line as well as adding the
                    translation marker (<b>|&gt;</b>). Once translation markers have been added, the button can&apos;t
                    be used again. Please note that this button is not perfect and you might still need to manually
                    adjust the text (removing unwanted characters, etc.).
                </p>
            </HelpButton>
            {translation.submitTranslation.success ? (
                <div className="d-flex justify-content-center alert alert-success">
                    Your translations submitted successfully.
                </div>
            ) : null}
            <ErrorHandling error={translation.error} loading={translation.loading} />
            <ErrorHandling error={textError} />
            <div className="row">
                <button
                    className="btn btn-primary col"
                    onClick={() => onOrganizeClick()}
                    disabled={text.includes('|>')}
                >
                    Organize
                </button>
                <button className="btn btn-secondary col" onClick={() => onResetClick()}>
                    Reset
                </button>
            </div>
            <div className="row">
                <textarea
                    className="form-control fs-4"
                    rows={8}
                    value={text}
                    onChange={onTextChange}
                    onBlur={onTextBlur}
                />
            </div>
            {translation.nextPageLines && <pre className="row my-3 fs-5">{translation.nextPageLines}</pre>}
            <div className="row">
                <hr />
                <button className="btn btn-primary col mb-4" disabled={!!textError} onClick={onSubmitTranslation}>
                    Submit
                </button>
            </div>
        </div>
    )
}
