import { PAGE_TRANSLATION_ACTIONS } from '../actions/translations'
import { createReducer } from '@reduxjs/toolkit'
import { TranslationStoreType, ReduxActionData } from '../../types/reducers'

const initialState: TranslationStoreType = {}

const dataState = createReducer(initialState, (builder) =>
    builder
        .addCase(PAGE_TRANSLATION_ACTIONS.GET_TRANSLATIONS_LOADING, (state, action: ReduxActionData<any>) => {
            state[action.meta.pageId] = {
                loading: true,
                lines: [],
                nextPageLines: '',
                error: null,
                translationText: '',
                submitTranslation: { loading: false, error: null, success: null },
            }
        })
        .addCase(
            PAGE_TRANSLATION_ACTIONS.GET_TRANSLATIONS_LOADED,
            (state = initialState, action: ReduxActionData<any>) => {
                const page = state[action.meta.pageId]
                page.loading = false
                page.error = null
                page.lines = action.payload.data.lines
                page.nextPageLines = action.payload.data.next_page_text
            }
        )
        .addCase(PAGE_TRANSLATION_ACTIONS.GET_TRANSLATIONS_ERROR, (state, action: ReduxActionData<any>) => {
            const page = state[action.meta.pageId]
            page.loading = false
            page.error = action.payload.response.data
        })
        .addCase(PAGE_TRANSLATION_ACTIONS.SUBMIT_TRANSLATIONS_LOADING, (state, action: ReduxActionData<any>) => {
            const page = state[action.meta.pageId]
            page.submitTranslation.error = null
            page.submitTranslation.success = null
            page.submitTranslation.loading = true
        })
        .addCase(
            PAGE_TRANSLATION_ACTIONS.SUBMIT_TRANSLATIONS_LOADED,
            (state = initialState, action: ReduxActionData<any>) => {
                const page = state[action.meta.pageId]
                page.lines = action.meta.lines
                page.translationText = ''
                page.submitTranslation.loading = false
                page.submitTranslation.success = true
            }
        )

        .addCase(PAGE_TRANSLATION_ACTIONS.SUBMIT_TRANSLATIONS_ERROR, (state, action: ReduxActionData<any>) => {
            const page = state[action.meta.pageId]
            page.submitTranslation.error = action.payload.response.data
            page.submitTranslation.loading = false
        })
        .addCase(PAGE_TRANSLATION_ACTIONS.SAVE_TRANSLATION, (state, action: ReduxActionData<any>) => {
            const page = state[action.meta.pageId]
            page.translationText = action.payload.translationText
        })
)

export default dataState
