import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

interface HelpButtonProps {
    children: React.ReactNode
}

export const HelpButton: React.FC<HelpButtonProps> = ({ children }) => {
    const [modalShow, setModalShow] = React.useState(false)
    const onHide = () => setModalShow(false)
    return (
        <>
            <Button variant="secondary" className="btn-sm" onClick={() => setModalShow(true)}>
                Help?
            </Button>
            <Modal show={modalShow} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>{children}</Modal.Body>
            </Modal>
        </>
    )
}
