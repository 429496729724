import React, { useEffect } from 'react'
import { TranslationComponent } from '../components/translation/TranslationComponent'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store/reducers/store'
import { setActiveSection, setPageDetails } from '../store/actions/menu'
import { MenuSections } from '../types/reducers'
import { useParams } from 'react-router-dom'
import * as actions from '../store/actions/translations'
import { ErrorHandling } from '../components/general/ErrorHandling'

const Translation = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const pageID: string = params.page!
    const translations = useSelector((state: RootState) => state.translations[pageID])
    const translationAvailable = !!translations
    useEffect(() => {
        dispatch(setPageDetails({ pageID: params.page || null, documentID: params.id }))
        dispatch(setActiveSection(MenuSections.Translation))
        if (!translationAvailable) {
            dispatch(actions.getTranslations(params.page || null))
        }
    }, [dispatch, params, translationAvailable])
    if (!translations) {
        return <ErrorHandling error="" loading={true} />
    }
    return <TranslationComponent />
}

export default Translation
