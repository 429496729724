import React, { useEffect } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../store/actions/accounts'
import { ErrorHandling, FormikServerErrors } from '../components/general/ErrorHandling'
import { RootState } from '../store/reducers/store'
import { useNavigate } from 'react-router-dom'

export const Register: React.FC = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const register = useSelector((state: RootState) => state.accounts.register)
    const userInfo = useSelector((state: RootState) => state.accounts.userInfo)
    useEffect(() => {
        if (userInfo.is_active) {
            navigate('/')
        }
    }, [userInfo.is_active, navigate])

    const RegisterSchema = Yup.object().shape({
        username: Yup.string()
            .matches(
                /^[a-z0-9_]+$/,
                'Enter a valid username. Username may contain only lower case letters, numbers, and _ characters.'
            )
            .required('This field is required')
            .min(4, 'Must be 4 characters or more'),
        password: Yup.string().min(8, 'Must be 8 characters or more').required('This field is required'),
        password2: Yup.string()
            .required('This field is required')
            .oneOf([Yup.ref('password')], 'Passwords must match'),
        challenge: Yup.string().required('This field is required'),
        email: Yup.string().email('Invalid email'),
    })
    if (register.success) {
        return (
            <div className="d-flex justify-content-center alert alert-success">
                Your registration was successful. You can now &nbsp;
                <button className="btn btn-sm btn-primary" onClick={() => dispatch(actions.showLoginForm(true))}>
                    login.
                </button>
            </div>
        )
    }

    return (
        <div className="h-100">
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-lg-8 col-xl-6">
                        <div className="card rounded-3">
                            <div className="card-body p-4 p-md-5">
                                <h3 className="text-center mb-4 pb-2 pb-md-0 mb-md-5 px-md-2">Registration Info</h3>
                                <div className="d-flex justify-content-center">
                                    <ErrorHandling error={register.error} loading={register.loading} />
                                </div>
                                <Formik
                                    initialValues={{
                                        username: '',
                                        password: '',
                                        password2: '',
                                        challenge: '',
                                        email: '',
                                    }}
                                    validationSchema={RegisterSchema}
                                    onSubmit={(values) => {
                                        dispatch(actions.registerUser(values))
                                    }}
                                >
                                    {({ errors, touched }) => (
                                        <Form>
                                            <FormikServerErrors errors={register.error} />
                                            <div className="form-outline mb-4">
                                                <label className="form-label" htmlFor="username">
                                                    Username
                                                </label>
                                                <Field
                                                    name="username"
                                                    id="username"
                                                    className={`form-control ${touched.username && errors.username ? 'is-invalid' : ''}`}
                                                />

                                                <ErrorMessage name="username" component="div" className="text-danger" />
                                            </div>
                                            <div className="form-outline mb-4">
                                                <label className="form-label" htmlFor="email">
                                                    Email
                                                </label>
                                                <Field
                                                    name="email"
                                                    id="email"
                                                    className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                                                />
                                                <ErrorMessage name="email" component="div" className="text-danger" />
                                                Email is optional but if you don{'\''}t provide, you won{'\''}t be able to
                                                reset your password.
                                            </div>
                                            <div className="form-outline mb-4">
                                                <label className="form-label" htmlFor="password">
                                                    Password
                                                </label>
                                                <Field
                                                    name="password"
                                                    className={`form-control ${touched.password && errors.password ? 'is-invalid' : ''}`}
                                                    id="password"
                                                    type={'password'}
                                                />
                                                <ErrorMessage name="password" component="div" className="text-danger" />
                                            </div>
                                            <div className="form-outline mb-4">
                                                <label className="form-label" htmlFor="password2">
                                                    Confirm password
                                                </label>
                                                <Field
                                                    name="password2"
                                                    className={`form-control ${touched.password2 && errors.password2 ? 'is-invalid' : ''}`}
                                                    id="password2"
                                                    type={'password'}
                                                />
                                                <ErrorMessage
                                                    name="password2"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </div>
                                            <div className="form-outline mb-4">
                                                <label className="form-label" htmlFor="challenge">
                                                    Please write down the text in that image
                                                </label>
                                                <img
                                                    src="/resources/signup_challenge.png"
                                                    alt="Challenge"
                                                    className="img-fluid pb-2"
                                                />
                                                <Field
                                                    name="challenge"
                                                    className={`form-control ${touched.challenge && errors.challenge ? 'is-invalid' : ''}`}
                                                    id="challenge"
                                                />
                                                <ErrorMessage
                                                    name="challenge"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                                <b>Tip</b>: You can use&nbsp;
                                                <a href="https://editor.danacbe.com/" target="_blank" rel="noreferrer">
                                                    online Coptic editor
                                                </a>{' '}
                                                to write the Coptic text then paste it here.
                                            </div>

                                            <div className="form-outline mt-5 text-center">
                                                <button className="btn btn-primary" type="submit">
                                                    Register
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
