import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, NavLink, useNavigate, useParams } from 'react-router-dom'
import { RootState } from '../../store/reducers/store'
import { ROUTES } from '../../constants/routes-constants'
import * as actions from '../../store/actions/documents'
import { PageListingType } from '../../types/reducers'
import { createSelector } from '@reduxjs/toolkit'

type PageNavigationPropsType = {
    pageId: string
}

function range(size: number, startAt = 0) {
    return [...Array(size).keys()].map((i) => i + startAt)
}

const PageNavigation: React.FC<PageNavigationPropsType> = (props: PageNavigationPropsType) => {
    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const curDocumentID: number = useSelector((state: RootState) => state.documents.curDocument.pk)
    const pageList: [string, number][] = useSelector(
        createSelector(
            (state: RootState) => state.documents.curDocument.pages,
            (pages: PageListingType) =>
                Array.from(Object.values(pages), (page) => [page.pk.toString(), page.number]) as [string, number][]
        )
    )
    const isLoading = useSelector((state: RootState) => state.documents.loading)
    const curPageNumber = useSelector((state: RootState) => state.documents.curDocument.pages[props.pageId].number)
    const curPageIndex = pageList.findIndex((page) => page[1] === curPageNumber)

    const changePageNumber = useCallback(
        (newPageID: string) => {
            navigate(
                generatePath(ROUTES.DOCUMENT_DETAILS_ROUTE, {
                    id: curDocumentID.toString(),
                    page: newPageID,
                })
            )
        },
        [curDocumentID, navigate]
    )

    useEffect(() => {
        if (curDocumentID === 0 && !isLoading) {
            dispatch(actions.getDocumentDetails({ id: params.id }))
        }
    }, [dispatch, params.id, curDocumentID, isLoading])

    const onNextClicked = useCallback(() => {
        const page = pageList[curPageIndex + 1]
        if (!page) return
        changePageNumber(page[0])
    }, [changePageNumber, pageList, curPageIndex])
    const onPrevClicked = useCallback(() => {
        const page = pageList[curPageIndex - 1]
        if (!page) return
        changePageNumber(page[0])
    }, [changePageNumber, pageList, curPageIndex])

    if (isLoading || curDocumentID === 0) {
        return <div>Loading ...</div>
    }

    let validNumbers: number[]

    if (curPageIndex < 7) {
        validNumbers = range(10)
            .concat([-1])
            .concat(range(3, pageList.length - 4))
    } else if (curPageIndex > pageList.length - 9) {
        validNumbers = range(3)
            .concat([-1])
            .concat(range(10, pageList.length - 11))
    } else {
        validNumbers = range(3)
            .concat([-1])
            .concat(range(7, curPageIndex - 3))
            .concat([-1])
            .concat(range(3, pageList.length - 4))
    }

    return (
        <div className="row">
            <div className="col-12">
                <nav aria-label="Page navigation">
                    <ul className="pagination">
                        <li className="page-item">
                            <a className="page-link" href={void 0} onClick={onPrevClicked}>
                                Previous
                            </a>
                        </li>
                        {validNumbers.map((pageIndex, arrayIndex) => {
                            const page = pageList[pageIndex]
                            if (pageIndex === -1) {
                                return (
                                    <li className="page-item disabled" key={arrayIndex}>
                                        <a className="page-link" tabIndex={-1} aria-disabled="true">
                                            ...
                                        </a>
                                    </li>
                                )
                            }
                            const activeClass = props.pageId === page[0] ? 'active' : ''

                            return (
                                <li key={arrayIndex} className="page-item">
                                    <a
                                        className={`page-link ${activeClass}`}
                                        href={void 0}
                                        onClick={() => changePageNumber(page[0].toString())}
                                    >
                                        {page[1]}
                                    </a>
                                </li>
                            )
                        })}
                        <li className="page-item">
                            <a className="page-link" href={void 0} onClick={onNextClicked}>
                                Next
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export const BackToPageDetailsButton = () => {
    const params = useParams()
    if (!params.page || !params.id) return null
    return (
        <NavLink
            to={generatePath(ROUTES.DOCUMENT_DETAILS_ROUTE, params)}
            className="btn btn-sm btn-outline-secondary m-3"
        >
            &lt; Back
        </NavLink>
    )
}

export default PageNavigation
