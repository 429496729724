import React, { MouseEvent, useCallback } from 'react'
import { generatePath, NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/reducers/store'
import { ROUTES } from '../../constants/routes-constants'
import * as actions from '../../store/actions/documents'
import { saveDraft } from '../../store/actions/documents'

const EMPTY_PAGE = {
    pk: 0,
    transcription: '',
    ocr_text: '',
    draft: '',
    total_number_of_transcriptions: 0,
}

export const PageDetailsMenu: React.FC = () => {
    const dispatch = useDispatch()
    const activeUser = useSelector((state: RootState) => state.accounts.userInfo.is_active)
    const { pageID, documentID } = useSelector((state: RootState) => state.menu.pageDetails)
    const reviewLink = generatePath(ROUTES.DOCUMENT_REVIEW_ROUTE, { id: documentID, page: pageID })
    const translateLink = generatePath(ROUTES.DOCUMENT_TRANSLATE_ROUTE, { id: documentID, page: pageID })
    const page = useSelector((state: RootState) => state.documents.curDocument.pages[pageID] || EMPTY_PAGE)

    const onReset = useCallback(() => {
        dispatch(saveDraft({ pageId: parseInt(pageID), draft: page.transcription || page.ocr_text || '' }))
    }, [dispatch, pageID, page])

    const save = useCallback(
        () => dispatch(actions.saveTranscription({ page: page, text: page.draft, status: 'pending' })),
        [page, dispatch]
    )

    const submit = useCallback(
        () => dispatch(actions.saveTranscription({ page: page, text: page.draft, status: 'submitted' })),
        [page, dispatch]
    )

    const onViewModeChanged = useCallback(
        (event: MouseEvent) => {
            const target = event.target as HTMLSelectElement
            dispatch(actions.setPageViewMode(target.dataset.value))
        },
        [dispatch]
    )

    const pageViewMode = useSelector((state: RootState) => state.documents.pageViewMode)
    const isStaff = useSelector((state: RootState) => state.accounts.userInfo.is_staff || false)
    const notEnoughTranscriptions = (page.total_number_of_transcriptions || 0) < 2
    const unchanged = page.transcription === page.draft

    return (
        <>
            <li className="nav-item dropdown">
                <a
                    className={'nav-link dropdown-toggle' + (activeUser ? '' : ' disabled')}
                    href="#"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    Page
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <li>
                        <button
                            className="dropdown-item"
                            type="button"
                            onClick={save}
                            disabled={unchanged || page.complete}
                        >
                            Save
                        </button>
                    </li>
                    <li>
                        <button
                            className="dropdown-item"
                            type="button"
                            onClick={submit}
                            disabled={page.submitted || page.complete}
                        >
                            Submit
                        </button>
                    </li>
                    <li>
                        <button
                            className="dropdown-item"
                            type="button"
                            onClick={onReset}
                            disabled={unchanged || page.complete}
                        >
                            Reset
                        </button>
                    </li>
                    {isStaff ? (
                        <>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>
                            <li>
                                <NavLink
                                    className={
                                        'dropdown-item' + (page.complete || notEnoughTranscriptions ? ' disabled' : '')
                                    }
                                    to={reviewLink}
                                >
                                    Review
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    className={
                                        'dropdown-item' + (page.complete && page.next_page_complete ? '' : ' disabled')
                                    }
                                    to={translateLink}
                                >
                                    Translate
                                </NavLink>
                            </li>
                        </>
                    ) : null}
                    <li>
                        <hr className="dropdown-divider" />
                    </li>
                    <li>
                        <a
                            className="dropdown-item"
                            href="https://editor.danacbe.com?expand_alphabet_table=1"
                            title="Customize what keys on your keyboard should be translated to which coptic letters"
                            rel="noreferrer"
                        >
                            Customize Keyboard
                        </a>
                    </li>
                </ul>
            </li>
            <li className="nav-item dropdown">
                <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    View
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <li>
                        <button
                            className={'dropdown-item' + (pageViewMode == 'edit' ? ' active' : '')}
                            data-value="edit"
                            type="button"
                            onClick={onViewModeChanged}
                        >
                            Edit
                        </button>
                    </li>
                    <li>
                        <button
                            className={'dropdown-item' + (pageViewMode == 'ocr' ? ' active' : '')}
                            data-value="ocr"
                            type="button"
                            onClick={onViewModeChanged}
                        >
                            Original OCR
                        </button>
                    </li>
                    <li>
                        <button
                            className={'dropdown-item' + (pageViewMode == 'translation' ? ' active' : '')}
                            data-value="translation"
                            type="button"
                            onClick={onViewModeChanged}
                        >
                            Translation
                        </button>
                    </li>
                </ul>
            </li>
        </>
    )
}
