import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/reducers/store'
import React, { useCallback, useEffect, useState } from 'react'
import * as actions from '../../store/actions/documents'
import { getTranslations } from '../../store/actions/translations'
import { saveDraft } from '../../store/actions/documents'
import { getCurrentLine } from '../../utility/functions'
import { ErrorHandling } from '../general/ErrorHandling'
import ProgressiveImage from '../general/ProgressiveImage'
import { DocumentDetailsType, PageType } from '../../types/reducers'
import { Editor } from '../general/CopticEditor'

type PagePropsType = {
    pageId: string
    document?: DocumentDetailsType
}
export const Page = (props: PagePropsType) => {
    const dispatch = useDispatch()
    const activeUser = useSelector((state: RootState) => state.accounts.userInfo.is_active)
    const page: PageType = useSelector((state: RootState) => state.documents.curDocument.pages[props.pageId])
    const translation = useSelector((state: RootState) => state.translations[props.pageId])
    const text = page.draft || ''
    const viewMode = useSelector((state: RootState) => state.documents.pageViewMode)
    const [curPageID, setCurPageID] = useState(0)
    const [numberOfLinesChanged, setNumberOfLinesChanged] = useState(false)
    const modified = page?.transcription !== text
    const numberOfLines = page?.lines_data?.length || 0

    const onEditorCaretPositionChanged = (caret: number) => {
        // if page still loading or not loaded yet, ignore
        if (page?.loading !== false) return
        const { curLine, linesCount } = getCurrentLine(text, caret)
        const pageEl = window.document.getElementById('page-image-container')
        const image: HTMLImageElement = window.document.getElementById('page-image') as HTMLImageElement
        setNumberOfLinesChanged(linesCount !== numberOfLines)
        if (pageEl && image) {
            let yPos = page.lines_data![curLine]?.box[1]
            if (yPos) {
                yPos = yPos * (image.height / image.naturalHeight) - image.parentElement!.clientHeight / 2
                pageEl.scrollTo(0, yPos)
            }
        }
    }

    useEffect(() => {
        if (page?.loading === null || page?.loading === undefined) {
            dispatch(actions.getPageDetails(props.pageId))
        }
    }, [dispatch, page?.loading, props.pageId])

    useEffect(() => {
        if (page?.loading !== false && page!.pk === curPageID) {
            return
        }
        setCurPageID(page?.pk || 0)
    }, [curPageID, setCurPageID, page])

    useEffect(() => {
        if (viewMode === 'translation' && !translation) {
            dispatch(getTranslations(props.pageId))
        }
    }, [viewMode, dispatch, props.pageId, translation])

    const onImageLoad = useCallback(() => {
        const pageEl = window.document.getElementById('page-image-container')
        pageEl!.scrollTo(0, pageEl!.clientHeight / 2)
    }, [])

    const badgeClasses = 'badge rounded-pill pull-right'
    let badge = <span className={`${badgeClasses} bg-secondary`}>Saved</span>
    if (page?.complete) {
        badge = <span className={`${badgeClasses} bg-danger`}>ReadOnly</span>
    } else if (modified) {
        badge = <span className={`${badgeClasses} bg-info`}>Unsaved</span>
    } else if (page?.submitted) {
        badge = <span className={`${badgeClasses} bg-primary`}>Submitted</span>
    }

    if (!page) {
        return <div>Loading ...</div>
    }
    let transcriptionDisplay: string | React.ReactElement = ''
    switch (viewMode) {
        case 'ocr':
            transcriptionDisplay = page.ocr_text || ''
            break
        case 'translation':
            if (!translation) {
                transcriptionDisplay = ''
            } else if (translation.loading || translation.error) {
                transcriptionDisplay = <ErrorHandling error={translation.error} loading={translation.loading} />
            } else {
                transcriptionDisplay = (
                    <div>
                        {translation.lines.map((line, index) => (
                            <div key={index} className="transcription-line row">
                                <div className="col-6 coptic">{line.text}</div>
                                <div className="col-5 english">
                                    {line.translation}
                                    {line.comment ? <p className="comment">* {line.comment}</p> : null}
                                </div>
                            </div>
                        ))}
                    </div>
                )
            }
            break
    }
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-10 text-center">
                    Total number of submitted transcriptions for this page: {page.total_number_of_transcriptions || 0}
                </div>
                {(activeUser && <div className="col-2">{badge}</div>) || (
                    <div className="alert alert-warning col-12 m-3">Please login to be able to save your work</div>
                )}
                <ErrorHandling error={page.error} loading={page.loading} />
                <div className="col-12" id="page-image-container">
                    <span className="badge rounded-pill bg-warning" id="marker">
                        &nbsp;
                    </span>
                    <ProgressiveImage src={page.url} placeholder="/b/static/loading.png" onLoad={onImageLoad}>
                        {(src) => (
                            <img src={src} alt={`Page ${page.pk}`} id="page-image" className="img img-responsive" />
                        )}
                    </ProgressiveImage>
                    {/* Add extra white space below the image to make scrolling near the bottom accurate */}
                    <div id="page_bottom_spacer"></div>
                </div>
                {numberOfLinesChanged ? (
                    <ErrorHandling
                        error={
                            'Adding or Deleting lines is not permitted\n' +
                            'If a line contains no text, delete the text leaving the line empty\n' +
                            `Total number of lines should be ${numberOfLines}`
                        }
                    />
                ) : null}
                <Editor
                    className={'col-12 editor_iframe' + (viewMode == 'edit' ? '' : ' d-none')}
                    title="Coptic Editor"
                    rows={3}
                    text={text}
                    onTextChanged={(text) => dispatch(saveDraft({ pageId: page.pk, draft: text }))} // save draft
                    onCaretPositionChanged={onEditorCaretPositionChanged}
                    readonly={page?.complete}
                />
                <div id="transcription-display">{transcriptionDisplay}</div>
            </div>
        </React.Fragment>
    )
}
